import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import ProductsPage from "pages/products"

export const Head = () => (
  <SEO
    title="商品總覽"
    description="CHAMPION、GILDAN、CP Sports、TOP HOODIE 等品牌，款式多元、百萬庫存、品質卓越、火速到貨。"
    pathname="/products"
  />
)

export default () => (
  <Layout>
    <ProductsPage />
  </Layout>
)
